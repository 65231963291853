
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { SearchOutlined } from '@ant-design/icons-vue'
import UserTagById from '@/components/user/UserTagById.vue'
import { IUser } from '@/utils/types'
import FireUser from '@/services/users'

@Options({
  components: { UserTagById, SearchOutlined },
  directives: { maska },
  emits: ['update:onUpdate'],
})
export default class SearchUserInput extends Vue {
  @Prop({ default: [] })
  userIds!: string[]

  @Prop({ default: true })
  isDisplayMe!: boolean

  @Prop({ default: false })
  canRemoveMe!: boolean

  @Prop({ default: false })
  canSelectMe!: boolean

  selectedIds = [] as string[]

  keyword = ''
  usersList: IUser[] = []

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get usersListFilter() {
    if (!this.keyword) {
      return []
    }

    const userList = this.usersList.filter((item) => {
      const match1 = `${item.displayName}${item.email}`.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1

      const match2 = this.canSelectMe || item.id !== this.userInfo.id
      const match3 = !this.selectedIds.includes(item.id || '')

      return match1 && match2 && match3
    })

    return userList.splice(0, 4)
  }

  @Emit('update:onUpdate')
  onAdd(id: string) {
    this.keyword = ''
    this.selectedIds.push(id)
    return [...new Set(this.selectedIds)]
  }

  @Emit('update:onUpdate')
  onRemove(email: string) {
    const index = this.selectedIds.indexOf(email)
    this.selectedIds.splice(index, 1)
    return [...new Set(this.selectedIds)]
  }

  @Watch('userIds')
  async selectedIdsChanged() {
    this.selectedIds = this.userIds
    if (!this.usersList.length) {
      this.usersList = await FireUser.list()
    }
  }

  created() {
    this.selectedIdsChanged()
  }
}
