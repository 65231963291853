
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { SearchOutlined } from '@ant-design/icons-vue'
import { IUser } from '@/utils/types'
import FireUser from '@/services/users'

@Options({
  components: { SearchOutlined },
  directives: { maska },
  emits: ['update:onRemove'],
})
export default class UserTagById extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop({ default: true })
  removeable!: boolean

  user!: IUser
  loading = true

  @Emit('update:onRemove')
  onRemove() {
    return this.id
  }

  async created() {
    this.user = await FireUser.get(this.id)
    this.loading = false
  }
}
